/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('discounts', function (http, toast) {
    const url = config.apiURL + 'discounts/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data.message);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (by, page = 1, pageSize) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.type) {
                        params.push('filter[type]=' + by.type);
                    }
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results
                        for (let i in by) {
                            if (['id', 'type'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }).catch(errorHandler);
            });
        },
        generate: (len = 100) => {
            let _url = url + '/generate/' + len;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        add: (item) => {
            return http.post(url, item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (item) => {
            return http.put(url + item._id, item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        delete: (item) => {
            return http.delete(url + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        }
    };
});

